export class UtilisateurModel {
  id: string;
  prenomNom: string;
  compteurEnedis = 0;
  debutPlage: number;
  identifiant: string;
  motDePasse: string;
  role: string;
  actif: boolean;
}
