import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {map} from 'rxjs/operators';

import {UtilisateurModel} from '../../models/utilisateur';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Component({
  selector: 'app-utilisateurs',
  templateUrl: './utilisateurs.component.html',
  styleUrls: ['./utilisateurs.component.scss']
})
export class UtilisateursComponent implements OnInit {
  nouvelUtilisateur = new UtilisateurModel();
  displayedColumns: string[] = ['prenomNom', 'debutPlage', 'identifiant', 'role', 'actions'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private auth: AngularFireAuth,
    private db: AngularFirestore,
    private snackBar: MatSnackBar, private authService: AuthService, private router: Router) {
    this.db.collection('utilisateurs').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as UtilisateurModel;
        const id = a.payload.doc.id;
        return {
          id,
          prenomNom: data.prenomNom,
          debutPlage: data.debutPlage,
          identifiant: data.identifiant,
          role: data.role
        };
      }))
    ).subscribe((datas) => {
      this.dataSource = new MatTableDataSource(datas);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngOnInit() {
    this.authService.isLoggedIn.subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.router.navigate(['/connexion']);
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async valider() {
    if (!this.nouvelUtilisateur.prenomNom) {
      this.message('Le champs prénom et nom est obligatoire');
    } else if (!this.nouvelUtilisateur.debutPlage) {
      this.message('Le champs début plage est obligatoire');
    } else if (!this.nouvelUtilisateur.identifiant) {
      this.message('Le champs identifiant est obligatoire');
    } else if (!this.nouvelUtilisateur.motDePasse) {
      this.message('Le champs mot de passe est obligatoire');
    } else if (!this.nouvelUtilisateur.role) {
      this.message('Le champs rôle est obligatoire');
    } else {
      const credential = await this.auth.createUserWithEmailAndPassword(
        this.nouvelUtilisateur.identifiant + '@bbetelecom.fr', this.nouvelUtilisateur.motDePasse);
      this.db.collection('utilisateurs').doc(credential.user.uid).set(JSON.parse(JSON.stringify(this.nouvelUtilisateur)))
        .then(_ => {
          this.message('Utilisateur ajouté avec succès !');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  supprimer(id) {
    this.db.collection('utilisateurs').doc(id).delete().then(_ => {
      this.message('Utilisateur supprimé avec succès !');
    }).catch((error) => {
      console.log(error);
    });
  }

  message(message, duration = 3000) {
    this.snackBar.open(message, null, {duration});
  }
}
